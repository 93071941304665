<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CASH FLOW</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                v-model="bank_id"
                dense
                outlined
                label="Bank"
                :items="bank_items"
                item-value="id"
                item-text="bank_code_name"
                :rules="rules.combobox_rule"
                @change="selected_bank"
              ></v-select>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="bank_account_no"
                label="Account #"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="date"
                label="Date"
                type="date"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="2"
              cols="12"
            >
              <v-select
                v-model="transaction"
                dense
                outlined
                label="Type of Transaction"
                :items="['DEPOSIT','WITHDRAWAL']"
                :rules="rules.combobox_rule"
                @change="selected_transaction"
              ></v-select>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                v-model="category"
                dense
                outlined
                label="Category"
                :items="category_items"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="12"
              cols="12"
              v-if="transaction==='DEPOSIT'"
            >
              <v-checkbox
                v-model="is_no_member"
                label="No Specific Person"
                class="mt-0"
                hide-details
              ></v-checkbox>
              <v-combobox
                v-if="!is_no_member"
                :append-icon="icons.mdiAccountSearchOutline"
                v-model="search"
                @keyup="searching($event.target.value)"
                :items="search_items"
                item-value="id"
                item-text="name"
                placeholder="Search by (Account Name/Account #)"
                ref="memberCombobox"
                dense
                outlined
              ></v-combobox>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-text-field
                type="number"
                v-model="amount"
                label="Amount"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                @change="selected_transaction"
                @keyup="selected_transaction"
              ></v-text-field>
            </v-col>
            <v-col
              md="9"
              cols="12"
            >
              <v-text-field
                v-model="specify"
                label="Specify"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_branch_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            Date
          </th>
          <th class="text-center text-uppercase">
            Category
          </th>
          <th class="text-center text-uppercase">
            Deposit
          </th>
          <th class="text-center text-uppercase">
            Withdrew
          </th>
          <th class="text-center text-uppercase">
            Balance
          </th>
          <th class="text-center text-uppercase">
            Details
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in cash_flow_items"
          :key="item.id"
        >
          <td>{{ item.date }}</td>
          <td class="text-center">
            {{ item.category }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.deposit)}}
          </td>
          <td class="text-center">
            {{ formatPrice(item.withdrew)}}
          </td>
          <td class="text-center">
            {{ formatPrice(item.balance) }}
          </td>
          <td class="text-center">
            {{ item.members!=null?
            item.members.last_name+', '+item.members.first_name+' - '+item.specify
            :item.specify }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiToggleSwitch,
    mdiToggleSwitchOff,
    mdiLeadPencil,
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiToggleSwitchOff,
          mdiToggleSwitch,
          mdiLeadPencil,
        },
      }
    },
    data() {
      return {
        saving: false,
        alert: false,
        alert_message: '',

        is_no_member: false,

        search: '',
        search_member: '',
        search_items: [],

        specify: '',
        date: '',
        transaction: '',
        category: '',
        category_items: [],
        bank_items: [],
        bank_id: 0,
        cash_flow_items: [],

        amount: 0,
        deposit: 0,
        withdrew: 0,
        bank_account_no: ''
      }
    },
    mounted() {
      this.load_data()
    },
    computed: {
      ...mapGetters('authentication', ['position']),
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bank_depositories', ['get_list_of_bank_depository']),
      ...mapActions('members_information', ['search_all_members']),
      ...mapActions('cash_flow', ['register_cash_flow']),
      update_officers(name_of_district, officers, id) {
        this.district_id = id
        this.officers_dialog = true
        this.name_of_district = name_of_district
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_all_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      selected_bank() {
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(this.bank_id)
        if (index != -1) {
          this.bank_account_no = this.bank_items[index].account_no
          this.cash_flow_items = this.bank_items[index].cash_flow
        }
      },
      selected_transaction() {
        this.category_items = []
        switch (this.transaction) {
          case "DEPOSIT":
            this.withdrew = 0
            this.deposit = this.amount
            this.category_items.push('MEMBERSHIP FEE');
            this.category_items.push('AMORTIZATION');
            this.category_items.push('CASH DONATION');
            break
          case "WITHDRAWAL":
            this.search = ''
            this.search_member = ''
            this.withdrew = this.amount
            this.deposit = 0
            this.category_items.push('GCSPP - GOODLIFE CARE STUDENT PROTECTION PROGRAM');
            this.category_items.push('GCFP - GOODLIFE CARE FEEDING PROGRAM');
            this.category_items.push('GCPH - GOODLIFE CARE PROJECT HOPE');
            this.category_items.push('GCPNN - GOODLIFE CARE PROJECT NINGNING');
            this.category_items.push('GCLP - GOODLIFE CARE LIVELIHOOD PROGRAM');
            this.category_items.push('GCSFAP - GOODLIFE CARE STUDENT FINANCIAL ASSISTANCE PROGRAM');
            this.category_items.push('GCRO - GOODLIFE CARE RELIEF OPERATION');
            this.category_items.push('GCMP - GOODLIFE CARE MEDICAL PROGRAM');
            break
        }
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.specify = ''
        this.date = ''
        this.is_club = false
        this.bank_items = []
        this.load_data()
      },
      save_branch_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('member_id', this.search === '' ? 'null' : this.search.id);
          data.append('date_established', this.date);
          data.append('category', this.category);
          data.append('date', this.date);
          data.append('deposit', this.deposit);
          data.append('withdrew', this.withdrew);
          data.append('bank_id', this.bank_id);
          data.append('specify', this.specify.toUpperCase());
          this.register_cash_flow(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      load_data() {
        this.get_list_of_bank_depository()
          .then(response => {
            this.bank_items = response.data
            this.selected_bank()
          })
      },
    }
  }
</script>
